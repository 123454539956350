<template>
  <div class="d-flex flex-column wrapper">
    <div class="wk_breadcrumbs">
      <div class="wk_collection_heading">
        <v-breadcrumbs class="text-grey pa-0" :items="breadcrumb" divider="\">
          <template v-slot:title="{ item }">
            {{ item.title }}
          </template>
        </v-breadcrumbs>
        <a :href="stagingLink" target="_blank">go to staging view</a>
      </div>
      <div class="wk_collection_heading">
        <h1 class="wk_page_title">{{ selectedCollezione }}</h1>
        <!--        <span v-if="flag" :class="`fi fi-${flag}`"></span>-->
        <div v-if="cons && enabled && enabled.length > 1" class="wk_branch_select">
          <v-select
              v-model="cons"
              :items="enabled"
              label="Select a branch"
              outlined
              dense
              hide-details
              @select="switchBranch"
          ></v-select>
        </div>
        <div v-else class="wk_flags">
          <span
              v-for="flag in flags"
              v-bind:key="flag"
              :class="`fi fi-${flag}`"
          ></span>
        </div>
      </div>
    </div>

    <div class="align-center justify-center text-center" v-if="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
      ></v-progress-circular>
    </div>

    <DataTable
        v-if="isAdmin"
        :value="collezione"
        @rowReorder="onRowReorder"
        v-model:editingRows="editingRows"
        editMode="row"
        @row-edit-init="onRowEditInit"
        class="p-datatable-sm"
        lazy
        resizableColumns
        columnResizeMode="expand"
        @row-edit-save="onRowEditSave"
    >
      <Column rowReorder headerStyle="width: 3rem" :reorderableColumn="false"/>
      <Column field="id" header="img">
        <template #body="{ data, field }">
          <div
              class="wk_color_img"
              :style="
              data['collection_img']
                ? 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data['collection_img'] +
                  '.jpg)'
                : 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data[field] +
                  '_S.jpg)'
            "
          >
            <span
                class="wk_color_img_error"
                style="
                background-image: url(https://prod.abetlaminati.com/img/abet_alert.png);
              "
            ></span>
          </div>
        </template>
      </Column>
      <Column field="id" header="code"></Column>
      <Column field="name" header="name"></Column>
      <Column
          field="finitura_std"
          header="finish"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
        <template #body="{ data, field }">
          {{
            Array.isArray(data[field])
                ? printDescriptions(data[field])
                : data[field]
          }}
        </template>
      </Column>
      <Column
          field="spessore_std"
          header="thickness"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
      </Column>
      <Column
          field="formato_std"
          header="size"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
      </Column>
      <Column
          field="action_edit"
          :rowEditor="!isNewUser"
          style="width: 50px; min-width: 8rem"
          bodyStyle="text-align:right"
      ></Column>
    </DataTable>

    <DataTable
        v-else
        :value="collezione"
        v-model:editingRows="editingRows"
        editMode="row"
        @row-edit-init="onRowEditInit"
        class="p-datatable-sm"
        lazy
        resizableColumns
        columnResizeMode="expand"
        @row-edit-save="onRowEditSave"
        @row-edit-cancel="onRowEditCancel"
    >
      <Column field="id" header="img">
        <template #body="{ data, field }">
          <div
              class="wk_color_img"
              :style="
              data['collection_img']
                ? 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data['collection_img'] +
                  '.jpg)'
                : 'background-image: url(https://abetlaminati.com/CAMPIONI/' +
                  data[field] +
                  '_S.jpg)'
            "
          >
            <span
                class="wk_color_img_error"
                style="
                background-image: url(https://prod.abetlaminati.com/img/abet_alert.png);
              "
            ></span>
          </div>
        </template>
      </Column>
      <Column field="id" header="code"></Column>
      <Column field="name" header="name"></Column>
      <Column
          field="finitura_std"
          header="finish"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
        <template #body="{ data, field }">
          {{
            Array.isArray(data[field])
                ? printDescriptions(data[field])
                : data[field]
          }}
        </template>
        <template #editor="{ data, field }">
          <MultiSelect
              :options="finiture"
              v-model="data[field]"
              optionLabel="Descrizione"
              optionValue="Codice"
          >
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.Descrizione }}</div>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column
          field="spessore_std"
          header="thickness"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
        <template #editor="{ data, field }">
          <!--          <InputText v-model="data[field]" style="max-width: 100px" />-->
          <Dropdown
              v-model="data[field]"
              :options="spessori"
              placeholder="Select a thickness"
          ></Dropdown>
        </template>
      </Column>
      <Column
          field="formato_std"
          header="size"
          bodyStyle="padding: 0 20px; background-color: #b7b7b72c;"
          class="wk_column_grey"
      >
        <template #editor="{ data, field }">
          <!--          <InputText v-model="data[field]" />-->
          <Dropdown
              v-model="data[field]"
              :options="formati"
              placeholder="Select a format"
          ></Dropdown>
        </template>
      </Column>
      <Column
          v-if="!isNewUser"
          field="nascosto"
          header="hidden"
          bodyStyle="padding: 0 20px;"
      >
        <template #body="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true" disabled/>
        </template>
        <template #editor="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true"/>
        </template>
      </Column>
      <Column field="consociata" header="branch">
        <template #body="{ data, field }">
          <div class="wk_flags" v-if="data[field]">
            <span
                v-for="flag in flags"
                v-bind:key="flag"
                :class="`fi fi-${flag}`"
            ></span>
          </div>
          <div class="wk_flags" v-else>
            <img
                src="../../public/img/abet-globe.svg"
                alt="globe"
                width="20"
                height="20"
            />
          </div>
        </template>
      </Column>
      <Column field="consociata">
        <template #body="slotProps">
          <Button
              v-if="
              slotProps.data[slotProps.field] &&
              clickedRows.includes(slotProps.index)
            "
              icon="pi pi-undo"
              class="p-row-editor-init"
              @click="openRestoreDialog(slotProps.data, slotProps.index)"
          />
        </template>
      </Column>
      <Column
          field="action_edit"
          :rowEditor="!isNewUser"
          style="width: 50px; min-width: 8rem"
          bodyStyle="text-align:right"
      ></Column>
    </DataTable>

    <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
      {{ text }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"
        >Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>

  <v-btn
      variant="tonal"
      color="black"
      class="wk_help_button"
      text="Help"
      @click="sheet = !sheet"
  ></v-btn>

  <v-bottom-sheet v-model="sheet" inset>
    <v-card class="text-center" height="250">
      <v-card-text style="align-content: center">
        <v-btn variant="text" color="black" @click="sheet = !sheet"
        >Close
        </v-btn>
        <div class="wk_help_column">
          At the top right you can see the flag of your branch. <br/>
          By clicking the button <span class="pi pi-pencil mx-1"></span> in any
          row you will be able to:
          <ul>
            <li>
              define a new standard for this decor, specifying format, thickness
              and finish
            </li>
            <li>
              determine if this decor should be visible or not in your
              subsidiary simply clicking the hidden checkbox
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>

  <v-dialog v-model="dialog" width="auto">
    <v-card
        max-width="500"
        prepend-icon="mdi-undo"
        title="Restore"
        text="By clicking on confirm you will delete standard values for this branch. Global standard values will be restored."
    >
      <template v-slot:actions>
        <v-btn
            class="ms-auto"
            @click="
            dialog = false;
            onRowEditRestore();
          "
            text="Confirm"
            block
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<style scoped>
/*@import "primevue/resources/themes/lara-light-green/theme.css";*/
@import "../../node_modules/flag-icons/css/flag-icons.min.css";

.wk_breadcrumbs {
  font-size: 20px;
  margin: 50px 0;
  display: block;
}

.wk_collection_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 0;

  a {
    color: #9E9E9E;
    text-decoration: none;
  }

  a:hover {
    color: #9E9E9E;
    text-decoration: underline;
  }
}

.wk_flags {
  display: flex;
  gap: 0 5px;
}

.wk_branch_select {
  width: 33%;
  max-width: 100px;
  display: flex;
}


.wk_page_title {
  margin-top: 30px;
  font-weight: 600;
}

.wk_color_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200% auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
}

input.p-inputtext.p-component {
  transform: none;
  position: relative;
}

.wk_help_button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>

<script>
import axios from "axios";
import {
  API_URL,
  defaultTimeout,
  notifyConsAlreadyExist,
  notifyDeletion,
  notifyError,
  notifyLoading,
  notifySaving,
} from "@/utils/const";
import authHeader from "@/services/data.service";
import finishes from "@/data/finishes.json";
import fields from "@/data/fullsearch_fields_prod.json";

export default {
  data() {
    return {
      breadcrumb: [],
      loading: false,
      // data from APIs
      collezione: [],
      finiture: null,
      spessori: null,
      formati: null,
      cons: null,
      selectedCons: null,
      enabled: null,
      selectedCollezione: null,
      editingRows: [],
      text: "",
      dialog: false,
      snackbar: false,
      clickedRows: [],
      isAdmin: false,
      isNewUser: false,
      formato_tmp: null,
      spessore_tmp: null,
      finitura_tmp: null,
      nascosto_tmp: null,
      updateStatus: {
        hidden: false,
        updated: false,
      },
      sheet: false,
      toDelete: null,
      indexToDelete: null,
    };
  },
  computed: {
    flags() {
      if (this.cons === "na") {
        return ["us", "ca"];
      } else if (this.cons === "all") {
        return ["it"];
      } else if (this.cons === "uk") {
        return ["gb"];
      } else if (this.cons === "none") {
        return [null];
      } else {
        return [this.cons];
      }
    },
    stagingLink() {
      return this.cons ? `https://${this.cons}.abetlaminati.com/abet-collection__staging` : "https://abetlaminati.com/abet-collection__staging";
    },
  },
  mounted() {
    this.loading = true;

    this.getRole();
    this.finiture = finishes;
    this.spessori = fields.spessori;
    this.formati = fields.formati;

    this.selectedCollezione = this.$route.params.collezione;
    this.getCollezione(this.selectedCollezione);

    if (this.$route.meta.title && this.selectedCollezione) {
      document.title = this.$route.meta.title + this.selectedCollezione;
    } else {
      document.title = "ABET Collezione";
    }

    this.breadcrumb = [
      {
        title: "collections",
        disabled: false,
        href: "/collections",
      },
      {
        title: this.selectedCollezione,
        disabled: true,
        href: "",
      },
    ];

    this.loading = false;
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    printDescriptions(codes) {
      let descriptions = "";
      let i = 1;
      codes.forEach((code) => {
        let item = this.finiture.find((entry) => entry.Codice === code);
        if (item) {
          descriptions += item.Descrizione;
          if (i < codes.length) {
            descriptions += ", ";
          }
        }
        i++;
      });
      return descriptions;
    },
    async getRole() {
      await axios
          .get(API_URL + "auth", {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.data.data) {
              if (
                  response.data.data.role === "admin" &&
                  response.data.data.cons === "all"
              ) {
                this.isAdmin = true;
              }

              if (response.data.data.cons === "none") {
                this.isNewUser = true;
              }
            }
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
          });
    },
    async getCollezione(collectionName) {
      await axios
          .get(API_URL + "decorativi/" + collectionName, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.data.data) {
              this.collezione = response.data.data.collezione;
              this.cons = response.data.data.cons;
              this.selectedCons = this.cons;
              this.enabled = response.data.data.enabled;
              this.text = notifyLoading;
              this.snackbar = true;
            }
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
          });
    },
    async updateIndex(id, index) {
      const body = {_id: id, index: index};
      await axios
          .put(API_URL + "decorativi/reorder", body, {headers: authHeader()})
          .then(() => {
            this.text = notifySaving;
            this.snackbar = true;
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
          });
    },
    async updateStandard(id, formato_std, spessore_std, finitura_std) {
      const body = {
        _id: id,
        formato_std: formato_std,
        spessore_std: spessore_std,
        finitura_std: finitura_std,
      };
      await axios
          .put(API_URL + "cons/decorativo/standard", body, {
            headers: authHeader(),
          })
          .then(() => {
            this.updateStatus.updated = true;
          })
          .catch(() => {
            this.updateStatus.updated = false;
          });
    },
    async createNewDecorativo(
        id,
        name,
        collection_id,
        collection_name,
        cons,
        finitura_std,
        spessore_std,
        formato_std,
        lunghezza_std,
        larghezza_std,
        note,
        reference_id
    ) {
      const body = {
        id: id,
        name: name,
        collection_id: collection_id,
        collection_name: collection_name,
        nazione: cons,
        finitura_std: finitura_std,
        spessore_std: spessore_std,
        formato_std: formato_std,
        lunghezza_std: lunghezza_std,
        larghezza_std: larghezza_std,
        note: note,
        reference_id: reference_id,
      };
      return await axios
          .post(API_URL + `cons/decorativo/new`, body, {headers: authHeader()})
          .then((response) => {
            if (response.data.data && response.data.status === "success") {
              this.updateStatus.updated = true;
              return response.data.data;
            }
            return null;
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.text = notifyConsAlreadyExist;
            }
            this.updateStatus.updated = false;
            this.snackbar = true;
            return null;
          });
    },
    async openRestoreDialog(data, index) {
      this.toDelete = data;
      this.indexToDelete = index;
      this.dialog = true;
    },
    async getAndDeleteDecorativo(id, reference_id) {
      return await axios
          .get(API_URL + `decorativi/item/${reference_id}`, {
            headers: authHeader(),
          })
          .then(async (result) => {
            const body = {_id: id};
            return await axios
                .delete(API_URL + "cons/decorativo/delete", {
                  headers: authHeader(),
                  data: body,
                })
                .then((deletion) => {
                  if (deletion.status === 204) {
                    this.text = notifyDeletion;
                    this.snackbar = true;
                    return result.data;
                  }
                  return null;
                })
                .catch(() => {
                  this.text = notifyError;
                  this.snackbar = true;
                  return null;
                });
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
            return null;
          });
    },
    async toggleHidden(id, hidden) {
      const body = {
        _id: id,
        hidden: hidden,
      };
      await axios
          .put(API_URL + "decorativi/toggle", body, {headers: authHeader()})
          .then(() => {
            this.updateStatus.hidden = true;
          })
          .catch(() => {
            this.updateStatus.hidden = false;
          });
    },
    onRowReorder(event) {
      this.collezione = event.value;
      for (let i = 0; i < this.collezione.length; i++) {
        if (i !== this.collezione[i].index) {
          this.updateIndex(this.collezione[i]._id, i);
          this.collezione[i].index = i;
        }
      }
    },
    onRowEditInit(event) {
      let {index} = event;
      if (this.isAdmin) {
        let currentPath = this.$route.path;
        this.$router.push({path: `${currentPath}/${event.data._id}`});
      } else {
        this.formato_tmp = this.collezione[index]["formato_std"];
        this.finitura_tmp = this.collezione[index]["finitura_std"];
        this.spessore_tmp = this.collezione[index]["spessore_std"];
        this.nascosto_tmp = this.collezione[index]["nascosto"];
        this.consociata_tmp = this.collezione[index]["consociata"];
      }

      this.clickedRows.push(event.index);
    },
    async onRowEditSave(event) {
      let {newData, index} = event;

      if (this.nascosto_tmp !== newData.nascosto) {
        if (this.consociata_tmp) {
          // Update values related to the id (worldwide data)
          await this.toggleHidden(
              this.collezione[index]["reference_id"],
              newData.nascosto
          );
        } else {
          // Update values related to the reference id (branch data)
          await this.toggleHidden(
              this.collezione[index]["_id"],
              newData.nascosto
          );
        }
        this.collezione[index] = newData;
      } else {
        this.updateStatus.hidden = true;
      }

      if (
          this.formato_tmp !== newData.formato_std ||
          this.finitura_tmp !== newData.finitura_std ||
          this.spessore_tmp !== newData.spessore_std
      ) {
        if (this.consociata_tmp) {
          // Update standard values for the subsidiary
          await this.updateStandard(
              this.collezione[index]["_id"],
              newData.formato_std,
              newData.spessore_std,
              newData.finitura_std
          );
          this.collezione[index] = newData;
        } else {
          // Create new standard values for the subsidiary
          let result = await this.createNewDecorativo(
              newData.id,
              newData.name,
              newData.collection_id,
              newData.collection_name,
              this.cons,
              newData.finitura_std,
              newData.spessore_std,
              newData.formato_std,
              newData.lunghezza_std,
              newData.larghezza_std,
              newData.note,
              newData._id
          );
          this.collezione[index]._id = result._id;
          this.collezione[index].formato_std = result.formato_std;
          this.collezione[index].spessore_std = result.spessore_std;
          this.collezione[index].finitura_std = result.finitura_std;
          this.collezione[index].reference_id = result.reference_id;
          this.collezione[index].consociata = true;
        }
      } else {
        this.updateStatus.updated = true;
      }

      if (this.updateStatus.hidden && this.updateStatus.updated) {
        this.text = notifySaving;
      } else if (!this.updateStatus.hidden && !this.updateStatus.updated) {
        this.text = notifyError;
        this.snackbar = true;
      } else if (!this.updateStatus.hidden) {
        this.text = "There was an error while updating the hidden flag";
      } else if (!this.updateStatus.updated) {
        this.text = "There was an error while updating the standard";
      }

      this.snackbar = true;
      this.updateStatus = {
        hidden: false,
        updated: false,
      };
      this.clickedRows.splice(this.clickedRows.indexOf(event.index), 1);
    },
    onRowEditCancel(event) {
      this.clickedRows.splice(this.clickedRows.indexOf(event.index), 1);
    },
    async onRowEditRestore() {
      console.log(this.toDelete._id);
      let result = await this.getAndDeleteDecorativo(
          this.toDelete._id,
          this.toDelete.reference_id
      );
      this.collezione[this.indexToDelete]._id = result._id;
      this.collezione[this.indexToDelete].formato_std = result.formato_std;
      this.collezione[this.indexToDelete].spessore_std = result.spessore_std;
      this.collezione[this.indexToDelete].finitura_std = result.finitura_std;
      this.collezione[this.indexToDelete].reference_id = null;
      this.collezione[this.indexToDelete].consociata = false;

      const cancelButton = document.querySelector(".p-row-editor-cancel");
      if (cancelButton) {
        cancelButton.click();
      }
    },
    async switchBranch() {
      await axios
          .get(API_URL + "auth/branch", {
            headers: authHeader(),
          })
          .then(() => {
            // reload the page
          })
          .catch(() => {
            this.text = notifyError;
            this.snackbar = true;
            this.cons = this.selectedCons;
          });
    },
    checkIfImageExists(url) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        return img.src;
      } else {
        img.onload = () => {
          return img.src;
        };

        img.onerror = () => {
          return img.src;
        };
      }
    },
  },
};
</script>
